import { QueryKey, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileLocationSchema } from './schemas';

const schema = z.object({
  locations: z.array(businessProfileLocationSchema),
});

export type ResponseBusinessProfileAccountLocations = z.infer<typeof schema>;

export const getQueryKey = (shopId: number): QueryKey => [
  shopId,
  'businessProfileAccountLocations',
];

export const useBusinessProfileAccountLocationsQuery = (shopId: number) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/business_profile/account_locations`,
      );
      return schema.parse(camelCaseKeys(response));
    },
  });
};
