/// <reference types="google.accounts" />
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScript } from 'usehooks-ts';

import { Button, Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { useExchangeGoogleAuthCodeMutation } from 'hooks/business-profile/use-exchange-google-auth-code-mutation';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import GBP from 'images/google-business-profile/google-business-profile.png';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import styles from './styles.module.scss';

const GOOGLE_BUSINESS_API_SCOPE =
  'https://www.googleapis.com/auth/business.manage';

type Props = {
  shopId: Shop['shopId'];
};

export const Login = ({ shopId }: Props) => {
  const navigate = useNavigate();

  // Shop query only here for purposes of the tracking event below.
  const { data: shop, isSuccess: isShopSuccess } = useShopQuery(shopId);
  const {
    trackBusinessProfileViewedLogin,
    trackBusinessProfileClickedGoogleLogin,
    trackBusinessProfileGoogleLoginSuccess,
  } = useAnalytics();
  const shopIsRegister =
    (isShopSuccess && getShopHasFullRegister(shop)) ?? false;

  useEffect(() => {
    if (isShopSuccess) {
      trackBusinessProfileViewedLogin({
        shopId,
        isRegister: shopIsRegister,
      });
    }
  }, [trackBusinessProfileViewedLogin, isShopSuccess, shopIsRegister, shopId]);

  const googleIdentityScriptStatus = useScript(
    'https://accounts.google.com/gsi/client',
  );
  const googleIdentityClientRef =
    useRef<google.accounts.oauth2.CodeClient | null>(null);

  const { mutate: exchangeGoogleAuthCode } =
    useExchangeGoogleAuthCodeMutation();

  useEffect(() => {
    if (googleIdentityScriptStatus === 'error') {
      console.error('Failed to load Google Identity script');
    }

    if (googleIdentityScriptStatus === 'ready') {
      googleIdentityClientRef.current = google.accounts.oauth2.initCodeClient({
        client_id: import.meta.env.VITE_GOOGLE_BUSINESS_CLIENT_ID,
        scope: GOOGLE_BUSINESS_API_SCOPE,
        ux_mode: 'popup',
        callback: (response) => {
          exchangeGoogleAuthCode(
            {
              shopId,
              authorizationCode: response.code,
            },
            {
              onSuccess: () => {
                trackBusinessProfileGoogleLoginSuccess({
                  shopId,
                  isRegister: shopIsRegister,
                });
                navigate('..', { relative: 'path' });
              },
              onError(error) {
                // TODO: Display error UI in case of failed API request
                console.error(
                  'Failed to exchange Google authorization code: ',
                  error,
                );
              },
            },
          );
        },
        error_callback: (error) => {
          // TODO: Display error UI in case of Google error? (popup window failed to open, closed prematurely, etc.)
          console.error('Google error occurred: ', error);
        },
      });
    }
  }, [
    googleIdentityScriptStatus,
    navigate,
    exchangeGoogleAuthCode,
    shopId,
    shopIsRegister,
    trackBusinessProfileGoogleLoginSuccess,
  ]);

  const launchGoogleSignIn = () => {
    trackBusinessProfileClickedGoogleLogin({
      shopId,
      isRegister: shopIsRegister,
    });

    if (!googleIdentityClientRef.current) {
      return;
    }

    googleIdentityClientRef.current.requestCode();
  };

  return (
    <ContentTile>
      <div className={styles.header}>Do You Own Your Google Profile?</div>
      <div className={styles.container}>
        <img alt="google-business-profile" src={GBP} className={styles.img} />
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.subheader}>What you get:</div>
            <div className={styles.text}>
              <Icon icon="sparkle" /> Boost orders by 30%
            </div>
            <div className={styles.text}>
              <Icon icon="sparkle" /> Easily connect with customers
            </div>
            <div className={styles.text}>
              <Icon icon="sparkle" /> Key data points on customer interaction
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.subheader}>
              Log in to your google account to get started
            </div>
            <Button variant="primary" onPress={launchGoogleSignIn}>
              Log in with Google
            </Button>
          </div>
        </div>
      </div>
    </ContentTile>
  );
};
