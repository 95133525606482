import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import { RHFNumberField } from 'components/shared/rhf-number-field';
import Modal from 'components/shared/slice-modal';
import { MenuProductsCategory } from 'types/menu/api';
import { RegisterPrinter } from 'types/register-printing';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import styles from './styles.module.scss';

type MenuProductsStockFilter = ('in-stock' | 'out-of-stock')[];
type MenuProductsAvailabilityFilter = ('online' | 'offline')[];

export type MenuProductsFilters = {
  stock: MenuProductsStockFilter;
  availability: MenuProductsAvailabilityFilter;
  printers: string[];
  categories: string[];
  maxPrice: number;
  minPrice: number;
};

type Props = {
  shop: Shop;
  printerSettings: RegisterPrinter[];
  categories: MenuProductsCategory[];
  isOpen: boolean;
  closeModal: () => void;
  onFiltersChange: (filters: MenuProductsFilters) => void;
  shouldClearAllFilters: boolean;
  setShouldClearAllFilters: (value: boolean) => void;
};

const defaultOpenSectionsState = {
  stock: false,
  categories: false,
  availability: false,
  printers: false,
};

const validationSchema = z
  .object({
    minPrice: z.union([z.number().nonnegative(), z.nan()]),
    maxPrice: z.union([z.number().nonnegative(), z.nan()]),
    availableOnline: z.boolean(),
    availableOnRegister: z.boolean(),
    inStock: z.boolean(),
    outOfStock: z.boolean(),
    categories: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        isSelected: z.boolean(),
      }),
    ),
    printers: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        isSelected: z.boolean(),
      }),
    ),
  })
  .superRefine((data, ctx) => {
    if (data.minPrice > data.maxPrice) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        inclusive: true,
        message: 'Minimum price must be less than maximum price.',
        maximum: data.maxPrice,
        path: ['minPrice'],
        type: 'number',
      });
    }
  });

type FormValues = z.infer<typeof validationSchema>;

export const FiltersModal = (props: Props) => {
  const {
    closeModal,
    onFiltersChange,
    shouldClearAllFilters,
    setShouldClearAllFilters,
    isOpen,
  } = props;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      minPrice: Number.NaN,
      maxPrice: Number.NaN,
      inStock: false,
      outOfStock: false,
      availableOnline: false,
      availableOnRegister: false,
      categories: props.categories.map((it) => ({
        id: it.id,
        name: it.name,
        isSelected: false,
      })),
      printers: props.printerSettings.map((it) => ({
        id: it.id,
        name: it.name,
        isSelected: false,
      })),
    },
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });

  const clearAllFilters = useCallback(() => {
    reset();
    setOpenSections(defaultOpenSectionsState);
  }, [reset]);

  useEffect(() => {
    if (shouldClearAllFilters) {
      clearAllFilters();
      setShouldClearAllFilters(false);
    }
  }, [clearAllFilters, setShouldClearAllFilters, shouldClearAllFilters]);

  const onSubmit = (values: FormValues) => {
    const {
      availableOnRegister,
      availableOnline,
      inStock,
      outOfStock,
      categories,
      maxPrice,
      minPrice,
      printers,
    } = values;

    const availability: MenuProductsAvailabilityFilter = [];
    const stock: MenuProductsStockFilter = [];
    const printersNames: string[] = [];
    const categoriesNames: string[] = [];

    if (availableOnRegister) {
      availability.push('offline');
    }

    if (availableOnline) {
      availability.push('online');
    }

    if (inStock) {
      stock.push('in-stock');
    }

    if (outOfStock) {
      stock.push('out-of-stock');
    }

    for (const printer of printers) {
      if (printer.isSelected) {
        printersNames.push(printer.name);
      }
    }

    for (const category of categories) {
      if (category.isSelected) {
        categoriesNames.push(category.name);
      }
    }

    const filters: MenuProductsFilters = {
      categories: categoriesNames,
      printers: printersNames,
      availability,
      stock,
      minPrice: Number.isNaN(minPrice) ? NaN : minPrice * 100,
      maxPrice: Number.isNaN(maxPrice) ? NaN : maxPrice * 100,
    };

    onFiltersChange(filters);
    closeModal();
  };

  const { fields: categoryFields } = useFieldArray({
    control,
    name: 'categories',
  });

  const { fields: printerFields } = useFieldArray({
    control,
    name: 'printers',
  });

  const [openSections, setOpenSections] = useState(defaultOpenSectionsState);

  const toggleIsSectionOpen = (name: keyof typeof openSections) => {
    setOpenSections((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <Modal
      header="Filters"
      isFullScreenMobile
      isOpen={isOpen}
      noButtonText="Clear all"
      onClickNo={clearAllFilters}
      formId="filters-modal-form"
      onRequestClose={closeModal}
      yesButtonText="Show results"
    >
      <form id="filters-modal-form" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.content}>
          <div className={styles.field}>
            <Label>Price</Label>
            <div className={styles.priceFilterGrid}>
              <RHFNumberField
                aria-label="minimum price"
                aria-describedby="min-price-error"
                className={styles.amountInput}
                control={control}
                formatOptions={{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  roundingMode: 'trunc',
                }}
                error={undefined}
                isRequired
                minValue={0}
                name="minPrice"
                prefix="$"
              />
              <div className={styles.priceSeparator} />
              <RHFNumberField
                aria-label="maximum price"
                className={styles.amountInput}
                control={control}
                formatOptions={{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  roundingMode: 'trunc',
                }}
                error={undefined}
                isRequired
                minValue={0}
                name="maxPrice"
                prefix="$"
                rules={{
                  deps: ['minPrice'],
                }}
              />
            </div>
            <FormFeedback className={styles.errorMessage} id="min-price-error">
              {errors.minPrice?.message}
            </FormFeedback>
          </div>
          <CollapsibleTile
            title="Stock"
            isOpen={openSections.stock}
            setIsOpen={() => toggleIsSectionOpen('stock')}
            className={styles.tileContainer}
            headerClassName={styles.header}
            bodyClassName={styles.body}
          >
            <RHFCheckbox control={control} name="inStock" label="In stock" />
            <RHFCheckbox
              control={control}
              name="outOfStock"
              label="Out of stock"
            />
          </CollapsibleTile>
          <CollapsibleTile
            title="Categories"
            isOpen={openSections.categories}
            setIsOpen={() => toggleIsSectionOpen('categories')}
            className={styles.tileContainer}
            headerClassName={styles.header}
            bodyClassName={styles.body}
          >
            {categoryFields.map((field, index) => (
              <RHFCheckbox
                key={field.id}
                control={control}
                name={`categories.${index}.isSelected`}
                label={field.name}
              />
            ))}
          </CollapsibleTile>
          {getShopHasFullRegister(props.shop) && (
            <CollapsibleTile
              title="Availability"
              isOpen={openSections.availability}
              setIsOpen={() => toggleIsSectionOpen('availability')}
              className={styles.tileContainer}
              headerClassName={styles.header}
              bodyClassName={styles.body}
            >
              <RHFCheckbox
                control={control}
                name="availableOnline"
                label="Online"
              />
              <RHFCheckbox
                control={control}
                name="availableOnRegister"
                label="Register"
              />
            </CollapsibleTile>
          )}
          {getShopHasFullRegister(props.shop) &&
            props.printerSettings.length > 0 && (
              <CollapsibleTile
                title="Printers"
                isOpen={openSections.printers}
                setIsOpen={() => toggleIsSectionOpen('printers')}
                className={styles.tileContainer}
                headerClassName={styles.header}
                bodyClassName={styles.body}
              >
                {printerFields.map((field, index) => (
                  <RHFCheckbox
                    key={field.id}
                    control={control}
                    name={`printers.${index}.isSelected`}
                    label={field.name}
                  />
                ))}
              </CollapsibleTile>
            )}
        </div>
      </form>
    </Modal>
  );
};
