import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileSchema } from './schemas';
import { getQueryKey as getBusinessProfileQueryKey } from './use-business-profile-query';

const schema = z.object({
  businessProfile: businessProfileSchema,
});

export type ResponseGoogleBusinessProfile = z.infer<typeof schema>;

export const useCreateBusinessProfileMutation = () => {
  const queryClient = useQueryClient();
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async ({
      shopId,
      googleLocationId,
    }: {
      shopId: Shop['shopId'];
      googleLocationId: string;
    }) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/business_profile`,
        {
          business_profile: { google_location_id: googleLocationId },
        },
      );
      return schema.parse(camelCaseKeys(response));
    },
    onSuccess(data, variables) {
      queryClient.setQueryData(
        getBusinessProfileQueryKey(variables.shopId),
        data,
      );
    },
  });
};
