import { QueryKey, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileSchema } from './schemas';

const schema = z.object({
  businessProfile: businessProfileSchema,
});

export type ResponseGoogleBusinessProfile = z.infer<typeof schema>;

export const getQueryKey = (shopId: number): QueryKey => [
  shopId,
  'businessProfile',
];

export const useBusinessProfileQuery = (shopId: number) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/business_profile`,
      );
      return schema.parse(camelCaseKeys(response));
    },
  });
};
