import { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import cx from 'classnames';

import { Icon, Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Loading from 'components/shared/loading';
import { useShopPublishersQuery } from 'hooks/brand-manager';
import {
  ResponseGoogleBusinessProfile,
  useBusinessProfileQuery,
} from 'hooks/business-profile/use-business-profile-query';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import { ApiRequestError } from 'providers/api/helpers';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import * as paths from '../paths';
import { Results } from '../results';
import { WebsiteTile } from '../website-tile';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

export const Profile = ({ shopId }: Props) => {
  const {
    data: businessProfileData,
    isLoading: isBusinessProfileLoading,
    isError: isBusinessProfileError,
    error: businessProfileError,
    isSuccess: isBusinessProfileSuccess,
  } = useBusinessProfileQuery(shopId);

  const {
    data: shop,
    isSuccess: isShopSuccess,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  const isBusinessProfileNotFound =
    isBusinessProfileError &&
    businessProfileError instanceof ApiRequestError &&
    businessProfileError.status === 404;

  // If shop has no business profile, go to location selection to create one.
  if (isBusinessProfileNotFound) {
    return <Navigate to={paths.accountLocations} />;
  }

  return (
    <>
      <ContentTile>
        <div>
          <h2 className={styles.header}>Your Google Business</h2>

          {isBusinessProfileLoading || isShopLoading ? (
            <Loading />
          ) : isBusinessProfileSuccess && isShopSuccess ? (
            <BusinessLocationStatus
              businessProfile={businessProfileData.businessProfile}
              shop={shop}
            />
          ) : (
            <span>
              Something went wrong retrieving your Business Profile. Please try
              again or contact your Partner Success Manager.
            </span>
          )}
        </div>
      </ContentTile>
      <Results shopId={shopId} />
    </>
  );
};

type BusinessLocationStatusProps = {
  businessProfile: ResponseGoogleBusinessProfile['businessProfile'];
  shop: Shop;
};

function BusinessLocationStatus({
  businessProfile: { googleLocation, googleLocationId },
  shop,
}: BusinessLocationStatusProps) {
  const { metadata, storefrontAddress, title, websiteUri } = googleLocation;
  const {
    trackBusinessProfileViewed,
    trackBusinessProfileClickedManage,
    trackBusinessProfileClickedVerify,
  } = useAnalytics();

  // Publishers query only in this component for the purposes of attaching data
  // to the analytics event below.
  const { data: shopPublishersData, isSuccess: isShopPublishersSuccess } =
    useShopPublishersQuery(shop.shopId);
  const hasSentTrackingEventRef = useRef(false);

  const isLocationVerified = metadata.hasVoiceOfMerchant;
  const [, locationId] = googleLocationId.split('locations/');

  const primaryAddressLines = storefrontAddress?.addressLines ?? [];

  const secondaryAddressLines =
    storefrontAddress === null
      ? []
      : [storefrontAddress.locality, storefrontAddress.regionCode].filter(
          (s) => !!s,
        );

  const verificationBannerText = isLocationVerified
    ? 'Business is verified. Manage below.'
    : 'Business is unverified. Verify your business below.';

  useEffect(() => {
    if (isShopPublishersSuccess && !hasSentTrackingEventRef.current) {
      trackBusinessProfileViewed({
        shopId: shop.shopId,
        isRegister: getShopHasFullRegister(shop) ?? false,
        bannerText: verificationBannerText,
        googleTotalOrders: shopPublishersData.all.last28Days.orders.total ?? 0,
        googleTotalSales: shopPublishersData.all.last28Days.revenue.total ?? 0,
        gbpWebsite: websiteUri,
      });
      hasSentTrackingEventRef.current = true;
    }
  }, [
    trackBusinessProfileViewed,
    isShopPublishersSuccess,
    shop,
    shopPublishersData,
    verificationBannerText,
    websiteUri,
  ]);

  return (
    <>
      {/* TODO: Use CrustAlert component */}
      <div className={cx(styles.alert, styles['success'])}>
        <Icon icon={isLocationVerified ? 'checkmark' : 'info'} />
        <span>{verificationBannerText}</span>
      </div>
      <div className={styles.container}>
        <div className={styles.profileTile}>
          <Icon icon="shop" />
          <div>
            <p>{title}</p>
            {primaryAddressLines.length === 0 ? null : (
              <p>{primaryAddressLines.join(', ')}</p>
            )}
            {secondaryAddressLines.length === 0 ? null : (
              <p>{secondaryAddressLines.join(', ')}</p>
            )}
          </div>
        </div>
        <WebsiteTile shopId={shop.shopId} webUrl={websiteUri} />
      </div>
      <Link
        appearance="button"
        variant="secondary"
        onPress={() => {
          const eventProperties = {
            shopId: shop.shopId,
            isRegister: getShopHasFullRegister(shop) ?? false,
            location: googleLocation,
            isVerified: googleLocation.metadata.hasVoiceOfMerchant,
          };
          if (isLocationVerified) {
            trackBusinessProfileClickedManage(eventProperties);
          } else {
            trackBusinessProfileClickedVerify(eventProperties);
          }
        }}
        href={`https://business.google.com/${isLocationVerified ? 'edit' : 'verify'}/l/u${locationId}`}
        target="_blank"
      >
        {isLocationVerified ? 'Manage' : 'Verify'} on Google
      </Link>
    </>
  );
}
