import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, ButtonGroup, Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { RHFTextField } from 'components/shared/rhf-text-field';
import { useUpdateBusinessProfileLocationMutation } from 'hooks/business-profile/use-update-business-profile-location-mutation';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import { pageNames } from 'providers/analytics/events';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';
import { addHttpsProtocolToUrl, isValidHttpUrlString } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  webUrl: string | null;
};

export const WebsiteTile = ({ shopId, webUrl }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { mutate: updateBusinessProfileLocation, isLoading: isUpdating } =
    useUpdateBusinessProfileLocationMutation(shopId);
  const { data: shop, isSuccess: isShopSuccess } = useShopQuery(shopId);
  const {
    trackBusinessProfileClickedEditWebsite,
    trackBusinessProfileChangedWebsite,
    trackDisplayedError,
  } = useAnalytics();
  const isShopRegister =
    (isShopSuccess && getShopHasFullRegister(shop)) ?? false;

  const {
    control: url,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: { urlInput: webUrl },
    mode: 'onBlur',
  });

  useEffect(() => {
    if (webUrl) {
      setValue('urlInput', webUrl);
    }
  }, [webUrl, setValue]);

  const urlInputValue = watch('urlInput');

  const handleIsEditMode = () => {
    setIsEditMode((prevIsEditMode) => !prevIsEditMode);
  };

  const closeEditMode = () => {
    handleIsEditMode();
    setValue('urlInput', webUrl);
  };

  const validateUrlInput = (value: string | null) => {
    if (value === null || isValidHttpUrlString(addHttpsProtocolToUrl(value))) {
      return true;
    }
    return 'Please enter a valid URL.';
  };

  const handleValidSubmit = (values: { urlInput: string | null }) => {
    if (values.urlInput !== null) {
      updateBusinessProfileLocation(
        {
          shopId: shopId,
          websiteUrl: values.urlInput,
        },
        {
          onSuccess() {
            handleIsEditMode();
            toast.success(
              "Your Google Business Profile's website was successfully updated.",
            );
            trackBusinessProfileChangedWebsite({
              shopId,
              isRegister: isShopRegister,
              gbpWebsite: values.urlInput,
            });
          },
          onError() {
            const errorMessage =
              "We weren't able to update your Google Business Profile website at this time. Please try again or contact your Partner Success Manager.";
            toast.error(errorMessage);
            trackDisplayedError({
              shopId,
              isRegister: isShopRegister,
              errorText: errorMessage,
              page: pageNames.businessProfile,
            });
          },
        },
      );
    }
  };

  return (
    <ContentTile className={styles.tile}>
      <div className={styles.header}>
        <Icon icon="sparkle" className={styles.icon} />
        <p>Current GBP website</p>
      </div>
      {isEditMode ? (
        <form onSubmit={handleSubmit(handleValidSubmit)}>
          <RHFTextField
            control={url}
            label="Url"
            name="urlInput"
            placeholder="Enter your website url"
            rules={{ validate: validateUrlInput }}
          />
          <ButtonGroup className={styles.buttonGroup}>
            <Button
              appearance="link"
              isDisabled={isSubmitting || isUpdating}
              size="small"
              type="submit"
            >
              Save
            </Button>
            <Button
              appearance="link"
              isDisabled={isSubmitting || isUpdating}
              onPress={closeEditMode}
              size="small"
              variant="destructive"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </form>
      ) : (
        <>
          <p className={styles.url}>{urlInputValue}</p>
          <Button
            appearance="link"
            onPress={() => {
              trackBusinessProfileClickedEditWebsite({
                shopId,
                isRegister: isShopRegister,
                gbpWebsite: webUrl,
              });
              handleIsEditMode();
            }}
            size="small"
          >
            Edit
          </Button>
        </>
      )}
    </ContentTile>
  );
};
