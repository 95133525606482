import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Switch } from 'crust';

import Label from 'components/shared/label';
import { RHFNumberField } from 'components/shared/rhf-number-field';
import Modal from 'components/shared/slice-modal';
import Select from 'components/shared/slice-select';
import { useProductTypesPricesUpdateMutation } from 'hooks/menu/use-product-types-prices-update-mutation';
import { MenuProductsProduct } from 'types/menu/api';
import { Shop } from 'types/shops';
import { showUnexpectedErrorToast } from 'utilities/forms';

import { useBulkEditContext } from '../context';

import styles from './styles.module.scss';

const validationSchema = z.object({
  priceChangeType: z.enum(['percentage', 'flat']),
  action: z.enum(['increase', 'decrease']),
  amount: z
    .number({
      errorMap: () => ({
        message: 'Please enter an amount greather than 0',
      }),
    })
    .min(0.01)
    .max(Number.MAX_VALUE),
});

type FormValues = z.infer<typeof validationSchema>;

type Props = {
  products: MenuProductsProduct[];
  shop: Shop;
  isOpen: boolean;
  closeModal: () => void;
};

const options = [
  {
    label: 'Increase price',
    value: 'increase',
  },
  {
    label: 'Decrease price',
    value: 'decrease',
  },
] as const;

export const BulkChangePricingModal = (props: Props) => {
  const { isOpen, closeModal, shop, products } = props;
  const { control, watch, reset, handleSubmit, setValue } = useForm<FormValues>(
    {
      defaultValues: {
        amount: undefined,
        priceChangeType: 'flat',
        action: 'increase',
      },
      resolver: zodResolver(validationSchema),
      mode: 'onBlur',
    },
  );
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();
  const { mutate, isLoading } = useProductTypesPricesUpdateMutation(
    shop.shopId,
  );

  const isPriceChangeIsFlatNumber = watch('priceChangeType') === 'flat';
  const priceChangeTypeFormat = isPriceChangeIsFlatNumber ? '$' : '%';

  const handleClose = () => {
    closeModal();
    reset();
  };

  const onSubmit = (data: FormValues) => {
    /**
     * Collect all productTypes ids we want to update
     * In future, we will update bulkEditContext to select
     * productTypes instead of just products
     */
    let productTypeIds: number[] = [];

    for (const product of products) {
      if (selectedProductIds.has(product.id)) {
        const ids = product.types.map((it) => it.id);
        productTypeIds = [...productTypeIds, ...ids];
      }
    }

    const isDecreasePriceAction = data.action === options[1].value;
    const amount = isDecreasePriceAction ? data.amount * -1 : data.amount;

    mutate(
      {
        productTypeIds,
        roundTo: 0.1,
        flat: data.priceChangeType === 'flat' ? amount * 100 : undefined,
        percentage: data.priceChangeType === 'percentage' ? amount : undefined,
      },
      {
        onSuccess: () => {
          clearSelectedProductIds();
          handleClose();
          toast.success(
            'The price change has been applied successfully for selected products',
          );
        },
        onError: () => {
          showUnexpectedErrorToast();
        },
      },
    );
  };

  return (
    <Modal
      header="Change price"
      isOpen={isOpen}
      formId="bulk-price-edit-modal-form"
      noButtonText="Cancel"
      onClickNo={handleClose}
      yesButtonText="Save"
      isNoButtonDisabled={isLoading}
      isYesButtonDisabled={isLoading}
      onRequestClose={isLoading ? undefined : handleClose}
    >
      <form
        id="bulk-price-edit-modal-form"
        onSubmit={handleSubmit(onSubmit)}
        className={styles.formGrid}
      >
        <div>
          <Label htmlFor="price-change-action">Action</Label>
          <Controller
            control={control}
            name="action"
            render={({ field }) => (
              <Select
                {...field}
                inputId="price-change-action"
                options={options}
                value={options.find((option) => option.value === field.value)}
                onChange={(newValue) => {
                  if (newValue) {
                    setValue('action', newValue.value);
                  }
                }}
              />
            )}
          />
        </div>
        <div>
          <Label id="price-change-amount">Amount</Label>
          <RHFNumberField
            className={styles.amountInput}
            control={control}
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              roundingMode: 'trunc',
            }}
            isRequired
            minValue={0}
            name="amount"
            prefix={
              // If price change is flat number we should show $ sign as a prefix
              isPriceChangeIsFlatNumber ? priceChangeTypeFormat : undefined
            }
            suffix={
              // If price change is percentage number we should show % sign as a suffix
              !isPriceChangeIsFlatNumber ? priceChangeTypeFormat : undefined
            }
            // @ts-expect-error react-aria deprecated placeholder prop
            //
            // We want to use placeholder here, as we had some complaints
            // of the input behaviour. In future, we should either update types
            // to accept placeholder or improve the behaviour of the input itself
            placeholder="0.00"
            aria-labelledby="price-change-amount"
          />
        </div>
        <div>
          <Controller
            control={control}
            name="priceChangeType"
            render={({ field }) => {
              const isSelected = field.value === 'percentage';

              return (
                <Switch
                  {...field}
                  isSelected={isSelected}
                  label="Switch to percentage amount"
                  onChange={(isSelected) => {
                    if (isSelected) {
                      field.onChange('percentage');
                    } else {
                      field.onChange('flat');
                    }
                  }}
                />
              );
            }}
          />
        </div>
      </form>
    </Modal>
  );
};
