import { z } from 'zod';

export const businessProfileLocationSchema = z.object({
  name: z.string(),
  title: z.string(),
  websiteUri: z.string().nullable(),
  storefrontAddress: z
    .object({
      addressLines: z.array(z.string()).max(5),
      languageCode: z.string().optional(),
      locality: z.string().optional(),
      postalCode: z.string().optional(),
      regionCode: z.string(),
    })
    .nullable(),
  metadata: z.object({
    hasVoiceOfMerchant: z.boolean(),
    mapsUri: z.string(),
    placeId: z.string(),
  }),
});

export const businessProfileSchema = z.object({
  googleLocation: businessProfileLocationSchema,
  googleLocationId: z.string(),
  id: z.number(),
  shopId: z.number(),
  state: z.string(),
});
