import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileLocationSchema } from './schemas';
import {
  getQueryKey as getBusinessProfileQueryKey,
  ResponseGoogleBusinessProfile,
} from './use-business-profile-query';

const schema = z.object({
  location: businessProfileLocationSchema,
});

export type ResponseBusinessProfileAccountLocation = z.infer<typeof schema>;

export const useUpdateBusinessProfileLocationMutation = (
  shopId: Shop['shopId'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      shopId,
      websiteUrl,
    }: {
      shopId: Shop['shopId'];
      websiteUrl: string;
    }) => {
      const response = await authenticatedFetch.patch(
        `api/management/v1/shops/${shopId}/business_profile/location`,
        {
          location: { website_uri: websiteUrl },
        },
      );
      return schema.parse(camelCaseKeys(response));
    },
    onSuccess(data) {
      queryClient.setQueryData<ResponseGoogleBusinessProfile>(
        getBusinessProfileQueryKey(shopId),
        (prevBpData) => {
          if (prevBpData) {
            return {
              businessProfile: {
                ...prevBpData.businessProfile,
                googleLocation: data.location,
              },
            };
          }
        },
      );
    },
  });
};
